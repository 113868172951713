"use client";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@components/common/toast";
import { useToast } from "@hooks/use-toast";

export const Toaster = (): JSX.Element => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => {
        return (
          <Toast
            key={id}
            onClick={(e) => {
              e.stopPropagation();
            }}
            datatype="toast"
            {...props}
          >
            <div className="grid gap-1">
              {title && <ToastTitle datatype="toast">{title}</ToastTitle>}
              {description && (
                <ToastDescription datatype="toast">
                  {description}
                </ToastDescription>
              )}
            </div>
            {action}
            <ToastClose datatype="toast" />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
